var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loadData)?_c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
    streetViewControl: false,
    rotateControl: true,
    disableDefaultUI: false,
    mapTypeControl: true,
    styles: _vm.stylesMap
  }},scopedSlots:_vm._u([{key:"visible",fn:function(){return [(_vm.area)?_c('gmap-drawing-manager',{ref:"drawArea",attrs:{"polygon-options":_vm.polygonOptions,"shapes":_vm.shapes},scopedSlots:_vm._u([{key:"default",fn:function(draw){return [_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"d-flex justify-center align-center toolbar-map-client",class:{'position-toolbar-right': _vm.$vuetify.breakpoint.mdAndUp, 'position-toolbar-center': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12"}},[_c('v-btn-toggle',{attrs:{"rounded":"","dense":"","background-color":"blue-grey lighten-1","dark":"","active-class":"active-action"}},[_c('v-btn',{staticClass:"px-4",on:{"click":function($event){return draw.setDrawingMode('polygon')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-draw")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Seleccionar")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-4",attrs:{"disabled":!_vm.shapes.length > 0},on:{"click":function($event){return draw.deleteSelection()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Borrar")])],1)]}}],null,true)},[_c('span',[_vm._v("Debes Seleccionar una área antes de borrar.")])]),_c('v-btn',{staticClass:"px-4",on:{"click":_vm.saveMap}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Exportar")])],1),_c('v-btn',{staticClass:"px-4",on:{"click":function($event){return draw.setDrawingMode('')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-hand-right")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Salir")])],1)],1)],1)],1)]}}],null,false,3423922252)}):_vm._e(),_c('v-btn-toggle',{staticClass:"btn-option",attrs:{"dense":"","active-class":"blue-grey"},model:{value:(_vm.poi),callback:function ($$v) {_vm.poi=$$v},expression:"poi"}},[_c('v-btn',{attrs:{"value":1}},[_c('v-icon',{attrs:{"color":_vm.poi === 1 ? 'white' : 'blue-grey lighten-3'}},[_vm._v("mdi-map-marker-circle")])],1)],1)]},proxy:true}],null,false,3848334691)},[_c('gmap-info-window',{attrs:{"position":_vm.Window_Pos,"opened":_vm.Window_Open,"options":{
    maxWidth: 400,
    pixelOffset: { width: 0, height: -35 }
  }},on:{"closeclick":function($event){_vm.Window_Open=false}}},[_c('div',{staticClass:"row no-gutter ma-0"},[_c('div',{staticClass:"col col-12 pa-0"},[_c('div',{staticClass:"v-list v-sheet theme--light py-0",attrs:{"role":"list"}},[_c('div',{staticClass:"v-list-item theme--light px-0",attrs:{"tabindex":"-1","role":"listitem"}},[_c('div',{staticClass:"v-avatar v-list-item__avatar",staticStyle:{"height":"40px","min-width":"40px","width":"40px"}},[_c('i',{staticClass:"v-icon notranslate blue-grey lighten-5 mdi mdi-briefcase-account-outline theme--dark icono--text",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"v-list-item__content"},[_c('div',{staticClass:"v-list-item__title font-weight-bold primary--text"},[_vm._v("("+_vm._s(_vm.current_marker.co_cli)+") "+_vm._s(_vm.current_marker.name))]),(_vm.current_marker.ven)?_c('div',{staticClass:"v-list-item__subtitle caption blue-grey--text font-weight-medium text--darken-3"},[_c('v-icon',{attrs:{"color":"blue-grey","small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.current_marker.ven)+" ("+_vm._s(_vm.current_marker.co_ven)+") ")],1):_vm._e()])])])]),(_vm.current_marker.ven)?_c('div',{staticClass:"col col-12 pa-0 d-flex justify-end"},[_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.detailsClient()}}},[_vm._v("Ver Detalles")])],1):_vm._e()])]),_vm._l((_vm.pointsView),function(point,i){return _c('gmap-marker',{key:i,ref:"CliMarkers",refInFor:true,attrs:{"position":point.position,"clickable":true,"draggable":false,"title":point.name,"icon":point.icono,"animation":point.animation,"label":_vm.action ? {
    text: (i + 1).toString(),
    color: 'black',
    fontSize: _vm.labelSize, // Tamaño dinámico
    fontWeight: 'bold',
  } : ''},on:{"click":function($event){_vm.info ? _vm.openWindowInfo(point, i) : _vm.center=point.position}}})}),(_vm.action==true)?_c('gmap-polyline',{attrs:{"path":_vm.polylinePath,"options":{
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    }}}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }