<template>
  <gmap-map
    v-if="!loadData"
    :center="center"
    :zoom="zoom"
    :options="{
      streetViewControl: false,
      rotateControl: true,
      disableDefaultUI: false,
      mapTypeControl: true,
      styles: stylesMap
    }"
    style="width:100%; height: 100%;"
    ref="mapRef"
  >
    <gmap-info-window :position="Window_Pos" :opened="Window_Open" @closeclick="Window_Open=false" :options="{
      maxWidth: 400,
      pixelOffset: { width: 0, height: -35 }
    }">
      <div class="row no-gutter ma-0">
        <div class="col col-12 pa-0">
          <div role="list" class="v-list v-sheet theme--light py-0">
            <div tabindex="-1" role="listitem" class="v-list-item theme--light px-0">
              <div class="v-avatar v-list-item__avatar" style="height: 40px; min-width: 40px; width: 40px;">
                <i aria-hidden="true" class="v-icon notranslate blue-grey lighten-5 mdi mdi-briefcase-account-outline theme--dark icono--text"></i>
              </div>
              <div class="v-list-item__content">
                <div class="v-list-item__title font-weight-bold primary--text">({{ current_marker.co_cli }}) {{current_marker.name}}</div>
                <div v-if="current_marker.ven" class="v-list-item__subtitle caption blue-grey--text font-weight-medium text--darken-3">
                  <v-icon color="blue-grey" small>mdi-account</v-icon>
                  {{current_marker.ven}} ({{ current_marker.co_ven }})
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="current_marker.ven" class="col col-12 pa-0 d-flex justify-end">
          <v-btn color="info" small @click="detailsClient()">Ver Detalles</v-btn>
        </div>
      </div>
    </gmap-info-window>

    <gmap-marker
      v-for="(point, i) in pointsView"
      :position="point.position"
      :clickable="true"
      :draggable="false"
      @click="info ? openWindowInfo(point, i) : center=point.position"
      :title="point.name"
      :icon="point.icono"
      :key="i"
      :animation="point.animation"
      ref="CliMarkers"
      :label="action ? {
      text: (i + 1).toString(),
      color: 'black',
      fontSize: labelSize, // Tamaño dinámico
      fontWeight: 'bold',
    } : ''"
    >
    </gmap-marker>

    <!-- Añadir la polilínea aquí -->
    <gmap-polyline v-if="action==true"
      :path="polylinePath"
      :options="{
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      }"
    />

    <template #visible>
      <gmap-drawing-manager
        v-if="area"
        :polygon-options="polygonOptions"
        :shapes="shapes"
        ref="drawArea"
      >
        <template v-slot="draw">
          <v-row class="ma-0">
            <v-col
              cols="12"
              class="d-flex justify-center align-center toolbar-map-client"
              :class="{'position-toolbar-right': $vuetify.breakpoint.mdAndUp, 'position-toolbar-center': $vuetify.breakpoint.smAndDown }"
            >
              <v-btn-toggle
                rounded
                dense
                background-color="blue-grey lighten-1"
                dark
                active-class="active-action"
              >
                <v-btn
                  @click="draw.setDrawingMode('polygon')"
                  class="px-4"
                >
                  <v-icon left>mdi-draw</v-icon>
                  <span class="hidden-sm-and-down">Seleccionar</span>
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="draw.deleteSelection()"
                      class="px-4"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!shapes.length > 0"
                    >
                      <v-icon left>mdi-trash-can</v-icon>
                      <span class="hidden-sm-and-down">Borrar</span>
                    </v-btn>
                  </template>
                  <span>Debes Seleccionar una área antes de borrar.</span>
                </v-tooltip>
                <v-btn
                  @click="saveMap"
                  class="px-4"
                >
                  <v-icon left>mdi-download</v-icon>
                  <span class="hidden-sm-and-down">Exportar</span>
                </v-btn>
                <v-btn
                  @click="draw.setDrawingMode('')"
                  class="px-4"
                >
                  <v-icon left>mdi-hand-right</v-icon>
                  <span class="hidden-sm-and-down">Salir</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </template>
      </gmap-drawing-manager>
      <v-btn-toggle
        v-model="poi"
        class="btn-option"
        dense
        active-class="blue-grey"
      >
        <v-btn :value="1">
          <v-icon :color="poi === 1 ? 'white' : 'blue-grey lighten-3' ">mdi-map-marker-circle</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
  </gmap-map>
</template>

<script>
import { gmapApi } from 'gmap-vue';
import { getIconUrl } from '@/widgets/iconGenerator'

export default {
  name: 'ViewMaps',
  props: {
    points: {
      type: Array,
      default: () => ([])
    },
    action: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Boolean,
      default: true
    },
    info: {
      type: Boolean,
      default: false
    },
    route: {
      type: Boolean,
      default: false
    },
    area: {
      type: Boolean,
      default: false
    },
    centerable: {
      type: Object,
      default: () => ({})
    },
    animation: {
      type: Object,
      default: () => ({})
    },
    zoom: {
      type: Number,
      default: 7,
    },
    iconSize: {
      type: String,
      default: 'default',
    }
  },
  data: () => ({
    center: { lat: 8.955582, lng: -65.3220065 },
    Window_Pos: null,
    Window_Open: false,
    current_idx: null,
    current_marker: {},
    shapes: [],
    polylinePath: [],
    selectedArea: [],
    polygonOptions: {
      fillColor: '#777',
      fillOpacity: 0.4,
      strokeWeight: 2,
      strokeColor: '#999',
      draggable: false,
      editable: false,
      clickable: true
    },
    poi: 1
  }),
  computed: {
    google: gmapApi,
    pointsView() {
      return this.points.length > 0 ? this.points.map((point) => {
        return {
          ...point,
          icono: {
            url: this.iconSize === 'mini' ? getIconUrl(point.icon.markerColor, point.icon.statusColor, 'mini') : getIconUrl(point.icon.markerColor, point.icon.statusColor), labelOrigin: this.iconSize === 'mini' ? new this.google.maps.Point(12, 12) : new google.maps.Point(18, 18),
          },
          animation: point.animation ? point.animation : null
        }
      }) : [];
    },
    labelSize() {
      return this.iconSize === 'mini' ? '10px' : '15px'; // Ajusta el tamaño de la etiqueta dinámicamente
    },
    stylesMap() {
          return [
            {
              featureType: 'poi',
              stylers: [{ visibility: this.poi === 1 ? 'on' : 'off' }],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{ visibility: this.poi === 1 ? 'on' : 'off' }],
            },
          ]
      }
  },
  watch: {
    points: {
    immediate: true,
    handler(newPoints) {
      // Filtrar los puntos que tienen una posición válida
      const validPoints = newPoints.filter(point => point.position && point.position.lat);
      // Actualizar polylinePath con las posiciones válidas
      this.polylinePath = validPoints.map(point => point.position);
      //this.calculateRoute();
    }
  },
    centerable(val, oldVal) {
      this.center = val;
    },
    animation(val, oldVal) {
      if(oldVal.lat !== null) {
        const old_cli_index = this.pointsView.findIndex(v => v.position.lng === oldVal.lng && v.position.lat === oldVal.lat);
        if(old_cli_index >= 0 && this.$refs.CliMarkers[old_cli_index].$markerObject.getAnimation() !== null) {
          this.$refs.CliMarkers[old_cli_index].$markerObject.setAnimation(null);
        }
      }

      const cli_index = this.pointsView.findIndex(v => v.position.lng === val.lng && v.position.lat === val.lat);
      if(cli_index >= 0) {
        this.$refs.CliMarkers[cli_index].$markerObject.setAnimation(this.google.maps.Animation.BOUNCE);
      }
    },
  },
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      if (this.points.length > 0) {
        var bounds = new this.google.maps.LatLngBounds();
        this.points.forEach(point => {
          bounds.extend(point.position)
        });
        this.center = this.points[0].position;
      }
    });
  },
  methods: {
    calculateRoute() {
    if (this.points.length < 2) {
      console.error('Se necesitan al menos dos puntos para calcular una ruta.');
      return;
    }

    const maxWaypoints = 23; // Máximo permitido por la API
    const directionsService = new this.google.maps.DirectionsService();
    const directionsRenderer = new this.google.maps.DirectionsRenderer();
    directionsRenderer.setMap(this.$refs.mapRef.$mapObject);

    // Function to calculate route for a given segment
    const calculateSegmentRoute = (start, end, waypoints, callback) => {
        const request = {
            origin: start,
            destination: end,
            waypoints: waypoints,
            travelMode: this.google.maps.TravelMode.DRIVING
        };

        directionsService.route(request, (result, status) => {
            if (status === this.google.maps.DirectionsStatus.OK) {
                callback(null, result);
            } else {
                callback(new Error('Error al obtener la ruta: ' + status), null);
            }
        });
    };

    const calculateFullRoute = () => {
        const segments = [];
        for (let i = 0; i < this.points.length; i += maxWaypoints) {
            const segmentWaypoints = this.points.slice(i + 1, i + 1 + maxWaypoints).map(point => ({
                location: new this.google.maps.LatLng(point.position.lat, point.position.lng),
                stopover: true
            }));

            const start = new this.google.maps.LatLng(this.points[i].position.lat, this.points[i].position.lng);
            const end = new this.google.maps.LatLng(
                this.points[Math.min(i + maxWaypoints, this.points.length - 1)].position.lat,
                this.points[Math.min(i + maxWaypoints, this.points.length - 1)].position.lng
            );

            segments.push({ start, end, waypoints: segmentWaypoints });
        }

        const renderNextSegment = (index, combinedResult) => {
            if (index >= segments.length) {
                // All segments calculated, render the combined result
                directionsRenderer.setDirections(combinedResult);
                return;
            }

            calculateSegmentRoute(segments[index].start, segments[index].end, segments[index].waypoints, (err, result) => {
                if (err) {
                    console.error(err);
                    return;
                }

                if (index === 0) {
                    combinedResult = result;
                } else {
                    // Combine the legs and routes of the segments
                    combinedResult.routes[0].legs = combinedResult.routes[0].legs.concat(result.routes[0].legs);
                }

                renderNextSegment(index + 1, combinedResult);
            });
        };

        renderNextSegment(0, null);
    };

    calculateFullRoute();
},


    openWindowInfo: function (marker, idx) {
      this.Window_Pos = marker.position;
      if (this.current_idx == idx) {
        this.Window_Open = !this.Window_Open;
      } else {
        this.Window_Open = true;
        this.current_idx = idx;
        this.current_marker = { ...marker };
      }
    },
    saveMap() {
      if (this.shapes.length > 0) {
        if (this.shapes.length > 1 && this.$refs.drawArea.selectedShape === null) {
          this.$root.$showAlert(
            'Debe Seleccionar un Area para Exportar',
            'error',
          );
          return;
        }
        const indexPolySelected = this.shapes.findIndex(poly => poly.overlay.zIndex === this.$refs.drawArea.selectedShape.overlay.zIndex);
        const pathShapes = this.shapes.length > 1 ? this.shapes[indexPolySelected].overlay.getPath().getArray() : this.shapes[0].overlay.getPath().getArray();
        this.selectedArea = [];
        if (pathShapes.length > 0) {
          pathShapes.forEach(point => {
            this.selectedArea.push({
              lat: point.lat(),
              lng: point.lng(),
            })
          });
          var area = new this.google.maps.Polygon({ paths: this.selectedArea });
          const markersInsidePolygon = this.pointsView.filter(
            marker => {
              const coordenate = new this.google.maps.LatLng(marker.position.lat, marker.position.lng);
              return this.google.maps.geometry.poly.containsLocation(coordenate, area)
            });
          this.$emit('exportar', markersInsidePolygon);
        }
      } else {
        this.$root.$showAlert(
          'Selección incompleta. Debe unir todos los puntos del Área',
          'error',
        );
      }
    },
    detailsClient() {
      this.$emit('details', this.current_marker)
    }
  }
}
</script>

<style lang="sass" scoped>
.toolbar-map-client
  position: fixed
  & .v-item-group .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg, .active-action
    background: #272727 !important
.position-toolbar-right
  bottom:0
  right:-280px
.position-toolbar-center
  bottom:0
  right:0
  left:0
  margin: 2% auto
.btn-option
  position: absolute
  top: 0
  right: 50px
  margin: 10px
.v-list-item
  min-height: 40px !important

</style>
